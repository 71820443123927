import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    utils: {
      ukraine: 'Ukraine',
      pageNotFound: 'Page not found'
    },
    header: {
      menu: {
        is10Models: {
          name: 'Data from climate models',
          options: {
            notIs10ModelsLabel: 'Group by minimum, mean and maximum',
            is10ModelsLabel: 'Show data for 10 models'
          }
        },
        amount: {
          name: 'Amount',
          options: {
            variable: 'Relative',
            absolute: 'Absolute value, units. (not for all parameters)'
          }
        },
        scenario: {
          name: 'Scenario of emissions into the atmosphere',
          nameShort: 'Scenario of emissions',
          options: {
            SSP5: 'SSP5-8.5 (RCP8.5) is a high emissions scenario',
            SSP2: 'SSP2-4.5 (RCP4.5) is a moderate emissions scenario'
          }
        },
        project: {
          name: 'Research project',
          options: {
            CMIP6: 'CMIP6 (ISIMIP3b)',
            CORDEX: 'CMIP5 (Euro-CORDEX)'
          }
        },
        area: {
          name: 'Coverage area',
          options: {
            ukraine: 'Within the territory of Ukraine',
            transboundary: 'Taking into account the transboundary part of the basins'
          }
        },
        precipitation: {
          name: 'Precipitation and temperature',
          options: {
            reanalysis: 'Climate reanalysis (ERA5-Land)',
            observation: 'Observation',
            observationWind: 'Observations corrected for wind'
          }
        },
        evaporation: {
          name: 'Evaporation',
          options: {
            actual: 'Actual',
            potentially: 'Potentially'
          }
        },
        glossary: {
          name: 'Glossary'
        },
        source: {
          name: 'Data source'
        },
        howTo: {
          name: 'How to use the platform'
        },
        reset: {
          name: 'Reset to default settings'
        },
        access: {
          name: 'Accessibility tools',
          options: {
            textSize: 'Text size',
            cancel: 'Cancel'
          }
        }
      },
      selectBasin: 'Choose a basin'
    },
    mainPage: {
      logoBlock: {
        describe: 'Data portal on the impact of climate change on water resources in Ukraine',
        goToData: 'Go to the data'
      },
      infoBlock: {
        growTemperature: 'Increase in temperature over the past 15 years*',
        fallTemperature: 'Decrease in temperature over the past 15 years*',
        growPrecipitation: 'Increase in precipitation over the past 15 years*',
        fallPrecipitation: 'Decrease in precipitation over the past 15 years*',
        growVolume: 'Increase in runoff volume over the past 15 years*',
        fallVolume: 'Decrease in runoff volume over the past 15 years*'
      },
      descriptionStart:
        '*Average value',
      descriptionEnd: 'over the past 15 years (2009-2023) compared to the period 1991-2020.',
      descriptionFor: 'for',
      table: {
        riverBasin: 'River',
        theVolumeRunoff: 'Runoff',
        temperature: 'Temp,',
        fall: 'Precipitation'
      },
      aboutPlatform: {
        title:
          '<span style="font-weight: bold;">Climate & Water (C & W)</span> is a user-friendly web service that visualizes the results of modeling the impact of climate change on water resources.',
        text1:
          'C & W contains data on air temperature, precipitation, water runoff, evaporation, groundwater, and soil moisture. The algorithms use the most precise hydrological model of Ukraine, historical climate data, and an ensemble of the latest high-resolution climate models.',
        text2:
          'For user convenience, the results are aggregated by river basins and water household areas in Ukraine. Users can explore annual, seasonal, or monthly values in chart or map format. All data is freely available and can be downloaded.',
        attention: 'The web service is currently in a trial phase.'
      }
    },
    dataPage: {
      tabs: {
        discharge: 'Discharge',
        water_flow: 'Water runoff',
        temperature: 'Temperature',
        precipitation: 'Precipitation',
        evapotranspiration: 'Evaporation',
        groundwater: 'Groundwater',
        soil_water: 'Soil water'
      },
      describe: {
        climateChange: 'Climate change in',
        discharge: 'Mean discharge change',
        water_flow: 'Mean water runoff change',
        temperature: 'Mean temperature change',
        precipitation: 'Mean precipitation change',
        evapotranspiration: 'Mean evapotranspiration change',
        groundwater: 'Mean groundwater change',
        soil_water: 'Mean soil water change',
        in: 'in',
        rcp45: 'Model values under the RCP4.5 moderate emissions scenario',
        rcp45Short: 'RCP4.5 moderate emissions scenario',
        rcp85: 'Model values under the high emissions scenario RCP8.5',
        rcp85Short: 'RCP8.5 high emissions scenario'
      },
      period: {
        period: 'Period',
        avg: 'Mean value',
        riverCycle: 'Annual cycle',
        map: 'On the map of the basin',
        selectPeriod: 'Select period',

        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
        spring: 'Spring',
        summer: 'Summer',
        autumn: 'Autumn',
        winter: 'Winter',
        annual: 'Annual',

        back: 'Back'
      },
      compare: {
        compare: 'Compare',
        default: 'Compare with',
        norm: 'The norm for 1991-2020',
        null: 'Do not compare'
      },
      chart: {
        mod: 'Mod',
        median: 'Median',
        description1: 'Data source or basis for calculations',
        description2: '*Relative to the baseline period 1991-2020',
        description2end: "for the outlet of the basin",
        description2household: "for the outlet of the water household area",
        norm: 'Norm for 1991-2020',
        diapazonLegend: 'Range of deviations',
        nodata: 'No data for chart',
        selectModel: 'Select a model or median:'
      },
      mapTable: {
        location: 'Location',
        za: "",
        january: 'january',
        february: 'february',
        march: 'march',
        april: 'april',
        may: 'may',
        june: 'june',
        july: 'july',
        august: 'august',
        september: 'september',
        october: 'october',
        november: 'november',
        december: 'december',
        spring: 'spring',
        summer: 'summer',
        autumn: 'autumn',
        winter: 'winter',
        annual: 'annual',
        for: 'in',
        rcp85: 'under the high emissions scenario RCP8.5',
        rcp45: 'under the moderate emissions scenario RCP4.5'
      }
    },
    pageNotFinished: {
      title1: 'This page is currently under development.',
      title2: 'We will be back soon with new information!',
      goHome: 'Home page'
    },
    footer: {
      mainPage: 'Home page',
      holosary: 'Glossary',
      howPlatform: 'How to use the platform',
      dataSources: 'Data sources',
      otherPlatforms: 'Other useful platforms:'
    },
    howToUse: {
      mainTitle: "Як користуватися платформою",
      abstractNav: "",
      howToUseChartsAndMapsNav: "",
      howToUseMainMenuNav: "",
      amountNav: "",
      scenarioEmissionsNav: "",
      researchProjectNav: "",
      coverageAreaNav: "",
      precipitationTemperatureNav: "",
      evaporationNav: "",
      resetDefaultSettingsNav: "",

      abstractContent: "<div class='small-container'>The top panel of the platform (header) contains a <b>logo</b> that can be clicked on <b>to return to the main page</b> (Fig. 1).</div>"
    }
  },
  uk: {
    utils: {
      ukraine: 'Україна',
      pageNotFound: 'Сторінку не знайдено'
    },
    header: {
      menu: {
        is10Models: {
          name: 'Дані моделей клімату',
          options: {
            notIs10ModelsLabel: 'Групувати як мінімум, середнє і максимум',
            is10ModelsLabel: 'Показати дані по 10 моделям'
          }
        },
        amount: {
          name: 'Кількість',
          options: {
            variable: 'Відносна зміна',
            absolute: 'Абсолютне значення, од. (не для всіх параметрів)'
          }
        },
        scenario: {
          name: 'Сценарій викидів в атмосферу',
          nameShort: 'Сценарій викидів',
          options: {
            SSP5: 'SSP5-8.5 (RCP8.5)— сценарій високих викидів',
            SSP2: 'SSP2-4.5 (RCP4.5) — сценарій помірних викидів'
          }
        },
        project: {
          name: 'Проєкт дослідження',
          options: {
            CMIP6: 'CMIP6 (ISIMIP3b)',
            CORDEX: 'CMIP5 (Euro-CORDEX)'
          }
        },
        area: {
          name: 'Територія охоплення',
          options: {
            ukraine: 'У межах території України',
            transboundary: 'Із урахуванням транскордонної частини басейнів'
          }
        },
        precipitation: {
          name: 'Опади та температура',
          options: {
            reanalysis: 'Кліматичний реаналіз (ERA5-Land)',
            observation: 'Спостереження',
            observationWind: 'Спостереження із поправкою на вітер'
          }
        },
        evaporation: {
          name: 'Випаровування',
          options: {
            actual: 'Фактичне',
            potentially: 'Потенційне'
          }
        },
        glossary: {
          name: 'Глосарій'
        },
        source: {
          name: 'Джерела даних'
        },
        howTo: {
          name: 'Як користуватись платформою'
        },
        reset: {
          name: 'Скинути до налаштувань за замовчуванням'
        },
        access: {
          name: 'Інструменти доступності',
          options: {
            textSize: 'Розмір тексту',
            cancel: 'Скасувати'
          }
        }
      },
      selectBasin: 'Виберіть басейн'
    },
    mainPage: {
      logoBlock: {
        describe: 'Портал даних про вплив змін клімату на водні ресурси України',
        goToData: 'Перейти до даних'
      },
      infoBlock: {
        growTemperature: 'Збільшилась температура за останні 15 років*',
        fallTemperature: 'Зменшилась температура за останні 15 років*',
        growPrecipitation: 'Збільшилась кількість опадів за останні 15 років*',
        fallPrecipitation: 'Зменшилась кількість опадів за останні 15 років*',
        growVolume: 'Збільшився об’єму стоку за останні 15 років*',
        fallVolume: 'Зменшився об’єму стоку за останні 15 років*'
      },
      descriptionStart:
          '*Середнє значення',
      descriptionEnd: 'за останні 15 років (2009-2023) відносно періоду 1991-2020 рр.',
      descriptionFor: 'для',
      table: {
        riverBasin: 'Річка',
        theVolumeRunoff: "Об'єм <br /> стоку",
        temperature: 'Тем-ра, <br />',
        fall: 'Опади'
      },
      aboutPlatform: {
        title:
          '<span style="font-weight: bold;">Climate & Water (C & W)</span> - це зручний для користувача сервіс, який візуалізує результати моделювання впливу кліматичних змін на водні ресурси.',
        text1:
          'C & W містить дані про температуру повітря, опади, водний стік, випаровування, підземні води та вологість ґрунту. Алгоритми використовують гідрологічну модель України, історичні кліматичні дані та ансамбль останніх кліматичних моделей з високою роздільною здатністю.',
        text2:
          'Для зручності користувачів результати представлено на рівні річкових басейнів України та виділених водогосподарських ділянок. Користувачі можуть ознайомитися з річними, сезонними або місячними значеннями у форматі графіку або мапи. Всі дані є безкоштовними та доступні для завантаження.',
        attention: 'Вебсервіс перебуває у дослідній експлуатації.'
      }
    },
    dataPage: {
      tabs: {
        discharge: 'Витрати води',
        water_flow: 'Об’єм стоку',
        temperature: 'Температура',
        precipitation: 'Опади',
        evapotranspiration: 'Випаровування',
        groundwater: 'Підземні води',
        soil_water: 'Вологість ґрунту'
      },
      describe: {
        climateChange: 'Зміни клімату в',
        discharge: 'Середні витрати води',
        water_flow: "Середній об'єм стоку",
        temperature: 'Середня температура повітря',
        precipitation: 'Опади',
        evapotranspiration: 'Випаровування',
        groundwater: 'Шар стоку ґрунтових вод',
        soil_water: 'Запас вологи ґрунту',
        in: 'у',
        rcp45: 'Значення моделей за сценарієм помірних викидів RCP4.5',
        rcp45Short: 'RCP4.5 - Сценарій помірних викидів',
        rcp85: 'Значення моделей за сценарієм високих викидів RCP8.5',
        rcp85Short: 'RCP8.5 - Сценарій високих викидів'
      },
      period: {
        period: 'Період',
        avg: 'Середнє значення',
        riverCycle: 'Річний цикл',
        map: 'На карті басейну',
        selectPeriod: 'Оберіть період',

        january: 'Січень',
        february: 'Лютий',
        march: 'Березень',
        april: 'Квітень',
        may: 'Травень',
        june: 'Червень',
        july: 'Липень',
        august: 'Серпень',
        september: 'Вересень',
        october: 'Жовтень',
        november: 'Листопад',
        december: 'Грудень',
        spring: 'Весна',
        summer: 'Літо',
        autumn: 'Осінь',
        winter: 'Зима',
        annual: 'Рік',
        back: 'Назад'
      },
      compare: {
        compare: 'Порівняти',
        default: 'Порівняти з',
        norm: 'Норма за 1991-2020 рр.',
        null: 'Не порівнювати'
      },
      chart: {
        mod: 'Мод',
        median: 'Медіана',
        description1: 'Джерело даних або на основі чого розрахунки',
        description2: '*Відносно базового періоду 1991-2020 рр.',
        description2end: 'для замикального створу басейну',
        description2household: "для замикального створу водогосподарської ділянки",
        norm: 'Норма за 1991-2020 рр.',
        diapazonLegend: 'Діапазон відхилень',
        nodata: 'Нема даних для графіка',
        selectModel: 'Оберіть модель або медіану:'
      },
      mapTable: {
        location: 'Ділянка',
        za: 'за',
        annual: 'рік',
        january: 'січень',
        february: 'лютий',
        march: 'березень',
        april: 'квітень',
        may: 'травень',
        june: 'червень',
        july: 'липень',
        august: 'серпень',
        september: 'вересень',
        october: 'жовтень',
        november: 'листопад',
        december: 'грудень',
        spring: 'весну',
        summer: 'літо',
        autumn: 'осінь',
        winter: 'зиму',
        for: 'у',
        rcp85: 'за сценарієм високих викидів RCP8.5',
        rcp45: 'за сценарієм помірних викидів RCP4.5'
      }
    },
    pageNotFinished: {
      title1: 'Ця сторінка зараз в процесі розробки.',
      title2: 'Ми скоро повернемося з новою інформацією!',
      goHome: 'На головну'
    },
    footer: {
      mainPage: 'Головна сторінка',
      holosary: 'Глосарій',
      howPlatform: 'Як користуватись платформою',
      dataSources: 'Джерела даних',
      otherPlatforms: 'Інші корисні платформи:'
    },
    howToUse: {
      mainTitle: "Як користуватися платформою",
      abstractNav: "Анотація",
      howToUseChartsAndMapsNav: "1. Як використовувати діаграми та карти?",
      howToUseMainMenuNav: "2. Як користуватися головним меню?",
      amountNav: "2.1 Кількість",
      scenarioEmissionsNav: "2.2 Сценарій викидів в атмосферу атмосферу",
      researchProjectNav: "2.3 Дослідницький проект",
      coverageAreaNav: "2.4 Територія покриття",
      precipitationTemperatureNav: "2.5 Опади та температура",
      evaporationNav: "2.6 Випаровування",
      resetDefaultSettingsNav: "3. Скидання до налаштувань за замовчуванням",

      abstractTitle: "",
      abstractContent: "<div class='small-container section'>" +
            "<p>Верхня панель платформи (шапка) містить <b>логотип</b>, на який можна натиснути, аби повернутися на <b>головну сторінку</b> (рис. 1).</p>" +
            '<img src="/image/infoPage/logo.jpg" alt="logo">' +
            '<b>Рис. 1</b> Логотип для переходу на головну сторінку' +
          "</div>" +
          "<div class='small-container section'>" +
            "<p>Щоб переглянути дані для басейну, суббасейну або водогосподарської ділянки, <b>виберіть потрібну опцію у випадаючому меню праворуч</b> (рис. 2).</p>" +
            '<img src="/image/infoPage/abstact-2.jpg" alt="abstact-2">' +
            '<b>Рис. 2</b> Вибір басейну, суббасейну або водогосподарської ділянки' +
          "</div>",

      howToUseChartsAndMapsTitle: "<div class='headline4'>1. Як користуватися діаграмами та картами</div>",
      howToUseChartsAndMapsContent: "<div class='small-container'><p>Гістограми показують відхилення параметрів (витрат води, об'єму стоку, температури повітря, опадів, випаровування, підземних вод, вологості ґрунту), усереднені за певний період часу (20 років), від довгострокового середнього значення (1991-2020 рр.) (рис. 3). Карти показують ті ж самі зміни, але подані просторово, що дозволяє легко оцінити, що очікується в різних місцях (рис. 4).</p>" +
            "<p>Оскільки деякі моделі не узгоджуються між собою з напрямком майбутніх змін кількості опадів (збільшення чи зменшення), важливо не покладатися лише на середнє значення або медіану результатів моделювання. Тобто, якщо дві моделі прогнозують збільшення, а дві моделі - зменшення, це буде приховано в середньому значенні. Тому <b>Climate & Water представляє медіану, а також діапазон результатів моделей (рис. 3).</b></p></div>" +
            "<img src='/image/infoPage/ukr_graph_1.jpg' alt='ukr_graph_1'> <div><b>Рис. 3</b> Графік параметра на платформі Climate & Water</div>" +
            "<div class='section'><img src='/image/infoPage/ukr_graph_2.jpg' alt='ukr_graph_1'> <div><b>Рис. 4</b> Опції для аналізу даних на платформі Climate & Water</div>" +
          "</div>",

      howToUseMainMenuTitle: "<div class='headline4'>2. Як користуватися головним меню?</div>",
      howToUseMainMenuContent: "",

      amountTitle: "<div class='label1-bold'>2.1 Кількість</div>",
      amountContent: "<div class='small-container section'>" +
            "<p>Опція <b>відносна зміна</b> відображає відносну зміну (% або 0C для температури), універсальну метрику, яку можна застосувати до даних, недоступних безпосередньо у <b>Climate & Water</b>. Наприклад, ви можете використати дані спостережень витрат води за 1991-2020 роки, застосувати відносну зміну та оцінити майбутні значення витрат.</p>" +
              "<p> <b>Абсолютні</b> значення розраховуються шляхом застосування відносних змін від кліматичних проекцій до історичних даних. Однак, історичні дані спостережень не є доступними для усіх територій або для деяких параметрів, за винятком кількох експериментальних ділянок (наприклад, випаровування, вологість ґрунту). Тому ми використали змодельовані значення, щоб заповнити прогалини в даних і забезпечити узгодженість між усіма територіями.</p>" +
              "<p> <img src='/image/infoPage/ukr_amount.png' alt='ukr_graph_1'> <div><b>Рис. 5</b> Кількість</div></p>" +
          "</div>",

      scenarioEmissionsTitle: "<div class='label1-bold'>2.2 Сценарій викидів в атмосферу</div>",
      scenarioEmissionsContent: "<div class='small-container section'>" +
          "<p><b>Сценарії викидів</b> варіюються від RCP 2.6 (SSP1-2.6), де люди рішуче і миттєво скорочують глобальні викиди, до RCP 8.5 (SSP5-8.5), де викиди продовжують зростати поточними темпами. Очевидно, що до 2024 року рівень RCP 2.6 буде недосяжним.</p>" +
          "<p>Тому <b>Climate & Water</b> фокусується на сценарії помірних викидів RCP 4.5 (SSP2-4.5) та сценарії високих викидів RCP 8.5. Однак, з точки зору зміни температури, всі сценарії демонструють схожі тренди приблизно до 2050 року.</p>" +
          "<p> <img src='/image/infoPage/ukr-emission.jpg' alt='emission'> <div><b>Рис. 6</b> Сценарій викидів в атмосферу</div></p>" +
          "</div>",

      researchProjectTitle: "<div class='label1-bold'>2.3 Дослідницький проект</div>",
      researchProjectContent: "<div class='small-container section'>" +
          "<p>Існує багато кліматичних моделей, і вони організовані в систему в рамках проєкту <b>CMIP (Coupled Model Intercomparison Project)</b>. У <b>Climate & Water</b> ми використовуємо дві останні фази цього проєкту: <b>CMIP5</b> та <b>CMIP6</b>. Інші проєкти, такі як Euro-CORDEX та <b>ISIMIP3b</b>, покращили роздільну здатність глобальних моделей, створених в рамках CMIP.</p>" +
          "<p><b>CMIP6</b> представляє останні кліматичні проєкції і включає більше факторів, ніж попередні версії. Однак це не гарантує, що прогнози <b>CMIP6</b> є більш точними, ніж прогнози CMIP5. При плануванні важливо враховувати весь спектр можливих результатів обох поколінь моделей.</p>" +
          "<p> <img src='/image/infoPage/ukr-research.jpg' alt='research'> <div><b>Рис. 7</b> Дослідницький проект</div></p>" +
          "</div>",

      coverageAreaTitle: "<div class='label1-bold'>2.4 Територія охоплення</div>",
      coverageAreaContent: "<div class='small-container section'>" +
          "<p>Усі річкові басейни в Україні, за винятком Південного Бугу, мають території, що охоплюють території сусідніх країн. Для забезпечення точного моделювання витрат води, Climate & Water включає верхні частини цих басейнів, розташовані за кордоном. Однак транснаціональна ділянка річки Дунай виключена через її великий розмір, що ускладнює її врахування в аналізі.</p>" +
          "<p> <img src='/image/infoPage/ukr-covarage.jpg' alt='covarage'> <div><b>Рис. 8</b> Територія охоплення</div></p>" +
          "</div>",

      precipitationTemperatureTitle: "<div class='label1-bold'>2.5 Опади та температура</div>",
      precipitationTemperatureContent: "<div class='small-container section'>" +
          "<p><b>Climate & Water</b> використовує ERA5-Land як вхідні дані для моделювання водного циклу за період 1991-2024 років. ERA5-Land - це глобальний набір даних кліматичного реаналізу суходолу з роздільною здатністю 0,1°x0,1° (близько 12x12 км в Україні).</p>" +
          "<p>Цей набір даних дозволяє моделювати ділянки річкових басейнів, розташованих за кордоном та на тимчасово окупованих територіях України. ERA5-Land представляє останнє покоління кліматичного реаналізу. Хоча він не ідеально відповідає наземним спостереженням, дослідження показали, що його точність є прийнятною для України <a href='https://meetingorganizer.copernicus.org/EGU24/EGU24-15129.html'>(Осадчий та ін., 2024)</a>, особливо для довгострокових середніх значень <a href='https://www.earthdoc.org/content/papers/10.3997/2214-4609.20215K2054'>(Бончковський та ін., 2021)</a>.</p>" +
          "<p>На доповнення до даних реаналізу, <b>Climate & Water</b> надає дані <b>спостережень за температурою повітря та опадами</b> для водозбірних басейнів. Ці дані отримані з уточнених часових рядів спостережень, нанесених на сітку 0,1° x 0,1°. Набір вихідних даних знаходиться у вільному доступі на сайті <a href='https://uhmi.org.ua/data_repo/ClimUAd_Ukrainian_gridded_daily'>Українського гідрометеорологічного інституту.</a></p>" +
          "<p>Третій варіант відображає дані <b>спостережень за опадами, з поправкою на вітрове відхилення</b>. Добре відомо, що фактична кількість опадів часто вища, ніж та, що вимірюється опадомірами. Ця розбіжність виникає через те, що вітер створює повітряні вихори навколо отвору опадоміра, перешкоджаючи потраплянню частини дощових крапель і особливо сніжинок всередину. В Україні це недоврахування становить 5-10% від загальної кількості опадів, залежно від методу, який використовується для розрахунку похибки <a href='https://periodicals.karazin.ua/geoeco/article/view/18400/16743'>(Осипов та ін., 2021)</a>. Ми розрахували поправку на вітер, використовуючи уточнені місячні значення спостережуваних опадів <a href='https://linkinghub.elsevier.com/retrieve/pii/S2352340922007600'>(Осадчий та ін., 2022)</a>.</p>" +
          "<p> <img src='/image/infoPage/ukr-temp.jpg' alt='ukr_graph_1'> <div><b>Рис. 9</b> Опади та температура</div></p>" +
          "</div>",

      evaporationTitle: "<div class='label1-bold'>2.6 Випаровування</div>",
      evaporationContent: "<div class='small-container section'>" +
          "<p><b>Фактичне випаровування (ETa)</b> - це те, що відбувається насправді. Це кількість води, яка фактично випаровується з землі та рослин в атмосферу. Вона залежить від кількості доступної води. Наприклад, у посушливій місцевості фактичне випаровування буде низьким, оскільки води для випаровування недостатньо, навіть якщо погода спекотна.</p>" +
          "<p><b>Потенційне випаровування (ETp)</b> - це те, що могло б статися за ідеальних умов. Це кількість води, яка могла б випаруватися, якби не було обмежень доступності води. Вона являє собою оцінку максимального випаровування і втрат води рослиною на основі погодних умов, таких як температура, вітер і сонячне світло, за умови, що води достатньо.\n</p>" +
          "<p>Наприклад, нам потрібні ці дані, щоб зрозуміти водний стрес:\n</p>" +
          "<p><b>ETp</b> показує, скільки води «вимагає» атмосфера залежно від погодних умов. Зі зміною клімату підвищення температури часто збільшує ETp, створюючи більший тиск на водні ресурси.\n</p>" +
          "<p><b>ETa</b> відображає, скільки води насправді є в наявності. Якщо ETa набагато нижче, ніж ETp, це свідчить про водний стрес, оскільки рослини і ґрунт не можуть задовольнити атмосферний попит.\n</p>" +
          "<p> <img src='/image/infoPage/ukr-evaporation.jpg' alt='evaporation'> <div><b>Рис. 10</b> Випаровування</div></p>" +
          "</div>",

      resetDefaultSettingsTitle: "<div class='label1-bold'>3. Скинути до налаштувань за замовчуванням</div>",
      resetDefaultSettingsContent: "<div class='small-container section'>" +
          "<p>Всі налаштування були налаштовані нами для отримання найбільш точної та повної інформації. Однак ви можете змінити будь-який пункт меню за потреби. У вас завжди є можливість повернутися до налаштувань за замовчуванням.</p>" +
          "<p> <img src='/image/infoPage/reset.jpg' alt='reset'> <div><b>Рис. 11</b> Скинути до налаштувань за замовчуванням</div></p>" +
          "</div>",
    },
    dataSource: {
      mainTitle: "Джерела даних",
      abstractDataNav: "Анотація",
      futureClimateDataSourcesNav: "1. Майбутні джерела кліматичних даних: Що таке \n" +
          "глобальні та регіональні кліматичні моделі.",
      historicalClimateDataSourcesNav: "2. Історичні джерела кліматичних даних: \n" +
          "Що таке кліматичний реаналіз.",
      ukrainianDailyAtmPrecipTempNav: "3. Історичні джерела кліматичних даних: \n" +
          "Українські щоденні атмосферні \n" +
          "опади та середня температура повітря\n" +
          "часові ряди",
      historicalFutureWaterCycleNav: "4. Історичний та майбутній кругообіг води: \n" +
          "Що таке гідрологічна модель?",
      sourcesOfInformationNav: "5. Джерела інформації",

      abstractDataTitle: "",
      abstractContent: "<div class='small-container section'><b>Climate & Water</b> поєднує кліматичні моделі з гідрологічною моделлю, яку ми налаштували для річкових басейнів України. Окрім цього, гідрологічна модель враховує щоденні дані щодо опадів, температури та інших показників для симуляції водного циклу за історичний період. У такий спосіб охоплено період з 1991 до 2100 року. Нижче ми розглянули джерела даних більш детально.</div>",
      futureClimateDataSourcesTitle: "<div class='headline4 small-container'>1. Джерела даних про клімат майбутнього: Що таке глобальні та регіональні кліматичні моделі?</div>",
      futureClimateDataSourcesContent: "<div class='small-container'>" +
            "<p>Глобальні кліматичні моделі (ГКМ) або моделі загальної циркуляції (МЗЦ) є найбільш розповсюдженим методом для оцінки можливих змін клімату в майбутньому внаслідок викидів парникових газів (глобального потепління). Моделі запускаються на суперкомп'ютерах, які намагаються змоделювати складні атмосферні та океанічні процеси, що визначають кліматичні умови, в яких ми живемо. Оскільки ГКМ працюють на глобальному рівні, їхні результати мають досить грубу роздільну здатність. Кожна комірка сітки має розмір приблизно 200×200 км. Регіональні кліматичні моделі (РКМ) застосовуються до менших за площею територій для отримання результатів з вищою локальною деталізацією.</p>" +
            "<p>У різних частинах світу існують різноманітні ГКМ і РКМ, розміщені в наукових центрах, зосереджених у країнах з високим рівнем розвитку, таких як Інститут метеорології імені Макса Планка (Німеччина), Центр кліматичних наук та послуг Метеорологічної служби Хадлі (Великобританія) та Національне управління океанічних і атмосферних досліджень (США). ГКМ і РКМ застосовуються для різних сценаріїв майбутніх викидів парникових газів - від найкращого сценарію (у разі проведення масштабних заходів зі скорочення обсягів викидів) до найгіршого сценарію (у випадку продовження зростання викидів без жодних заходів для їх скорочення).</p>" +
            "<p>Вони генерують низку можливих кліматичних сценаріїв (проєкцій). Наприклад, на п'ятому етапі проєкту CMIP5 (Coupled Model Intercomparison Project) порівнюються результати різних ГКМ, виконаних за одним і тим же набором чотирьох різних сценаріїв викидів, відомих як репрезентативні траєкторії концентрації (RCP).</p>" +
            "<p>" +
              "<img src='/image/infoPage/source-why.png' alt='source'>" +
              "<div><b>Рис. 1</b> Чому існує так багато кліматичних проєкцій?</div>" +
            "</p>" +
            "<p>З огляду на розмаїття ГКМ і РКМ - всі вони працюють за різними сценаріями - наразі доступний широкий спектр результатів кліматичних моделей. На рис. 1 наведено деякі з причин, чому існують різні результати і способи їх представлення для розуміння змін клімату.</p>" +
            "<p>Для подальшого уточнення і розширення розуміння впливу змін клімату існують додаткові ініціативи, такі як <a href='https://www.euro-cordex.net/'>Euro-CORDEX</a> та <a href='https://www.isimip.org/'>ISIMIP</a>, які базуються на результатах ГКМ і надають більш детальні та орієнтовані на конкретні застосування кліматичні проєкції.</p>" +
            "<p><a href='https://www.euro-cordex.net/'>Euro-CORDEX</a> - це міжнародна ініціатива, спрямована на створення кліматичних проєкцій з високою роздільною здатністю спеціально для Європи. Вона використовує регіональні кліматичні моделі (РКМ) для «уточнення» результатів глобальних кліматичних моделей (ГКМ), отриманих на етапах CMIP. Цей процес адаптує великомасштабні дані до особливостей географії Європи, враховуючи найдрібніші деталі, такі як гори, берегові лінії та локальні погодні умови. Ці прогнози з високою роздільною здатністю особливо корисні для таких галузей, як управління водними ресурсами, сільське господарство та міське планування, де вкрай важлива детальна інформація.</p>" +
            "<p><a href='https://www.isimip.org/'>ISIMIP</a> (Inter-Sectoral Impact Model Intercomparison Project) застосовує ширший підхід, пов'язуючи кліматичні проєкції з різними галузями, такими як сільське господарство, водне господарство, охорона здоров'я та довкілля. Він забезпечує основу для порівняння та узгодження результатів різних моделей впливу з використанням узгоджених кліматичних даних, таких як дані CMIP та Euro-CORDEX. Це дозволяє ефективно трансформувати кліматичні проєкції в практичні рекомендації для різних галузей, допомагаючи політикам і стейкхолдерам планувати майбутні виклики.</p>" +
            "<p>Climate & Water використовує шість кліматичних проєкцій з проєкту Euro-CORDEX (сітка 0,11°x0,11° або близько 13x13 км для України, Таблиця 1) та десять проєкцій з проєкту ISIMIP3b (сітка 0,50°x0,50° або близько 60x60 км для України, Таблиця 2) як вхідні дані для моделювання майбутнього водного циклу в рамках гідрологічної моделі. Вхідні дані складаються з п'яти метеорологічних параметрів, що реєструються щоденно: температура повітря (мінімальна, максимальна та середня), опади, швидкість вітру, вологість та сонячна радіація.</p>" +
            "<div><b>Таблиця 1</b> Перелік кліматичних моделей із проєкту Euro-CORDEX, що використано у Climate & Water</div>" +
            "<img src='/image/infoPage/table-models.jpg' alt='source'>" +
            "<p style='padding-top: 47px'><b>Таблиця 2</b> Перелік кліматичних моделей із проєкту ISIMIP3b, що використано у Climate & Water</p>" +
            "<img src='/image/infoPage/source-tab-2.png' alt='source'>" +
          "</div>",

      historicalClimateDataSourcesTitle: "<div class='headline4 small-container'>2. Історичні джерела кліматичних даних: Що таке кліматичний реаналіз?</div>",
      historicalClimateDataSourcesContent: "<div class='small-container'>" +
          "<p>Спостереження нерівномірно охоплюють земну кулю, а їхня кількість скорочується від сучасності до минулого. Проте, <a href='https://climate.copernicus.eu/climate-reanalysis'>кліматичний реаналіз</a> може вирішити цю проблему. Реаналіз проводиться шляхом поєднання результатів чисельного прогнозування погоди (<a href='https://www.ecmwf.int/en/research/climate-reanalysis'>модель ECMWF - IFS у випадку ERA5</a>) зі спостереженнями за Земною кулею (зокрема, із супутників, метеостанцій та інших інструментів у атмосфері, океані та на суші) за допомогою комплексного математичного підходу, відомого як «асиміляція даних». Результатом є глобально повний і узгоджений у часі набір даних, який охоплює понад 80 років, що дозволяє нам оцінювати довгострокові зміни клімату Землі.</p></div>" +
          "<p><iframe width=\"912\" height=\"515\" src=\"https://www.youtube.com/embed/FAGobvUGl24?si=AO6E9ucfio3gxWtG\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" referrerpolicy=\"strict-origin-when-cross-origin\" allowfullscreen></iframe></p>" +
          "<div class='small-container'><p>ERA5 - це найновіший <a href='https://www.ecmwf.int/en/research/climate-reanalysis'>кліматичний реаналіз, проведений ECMWF</a>, який надає погодинні дані щодо багатьох параметрів стану атмосфери, поверхні суші та моря разом з оцінками невизначеності. Дані ERA5 доступні в <a href='https://cds.climate.copernicus.eu/#!/home'>архіві кліматичних даних</a> на регулярних сітках широти-довготи з роздільною здатністю 0,25°x0,25°, з атмосферними параметрами на 37 рівнях тиску. <a href='https://cds.climate.copernicus.eu/datasets/reanalysis-era5-land?tab=download'>ERA5-Land</a> - це глобальний набір даних про стан земної поверхні з роздільною здатністю 0,10°x0,10° (близько 12x12 км для України), узгоджений з атмосферними даними, отриманими в результаті реаналізу ERA5, починаючи з 1950 року.</p>" +
          "<p class='with-back'>Climate & Water використовує кліматичний реаналіз ERA5-Land як вхідні дані для моделювання водного циклу за історичний період (1991-2024 рр.). Вхідні дані охоплюють п'ять метеорологічних параметрів щоденних спостережень: температура повітря (мінімальна, максимальна та середня), опади, швидкість вітру, вологість та сонячна радіація. Температура повітря та опади відображаються у вкладках «Температура» та «Опади».</p></div>",

      ukrainianDailyAtmPrecipTempTitle: "<div class='headline4 small-container'>3. Історичні джерела кліматичних даних: Добові ряди атмосферних опадів та середньої температури повітря в Україні</div>",
      ukrainianDailyAtmPrecipTempContent: "<div class='small-container'>" +
          "<p>У 2022 році група українських та міжнародних дослідників розробила <a href='https://uhmi.org.ua/data_repo/ClimUAd_Ukrainian_gridded_daily'>сіткові кліматичні часові ряди</a>, які охоплюють територію України за період 1946-2020 рр. (75 років). Набір даних було створено шляхом ретельної обробки історичних кліматичних даних, включаючи такі важливі кроки, як відновлення та оцифрування відсутніх значень з паперових записів, перевірка якості даних, забезпечення їх узгодженості (гомогенізація) та картографування на сітці 0,10°x0,10° (близько 12x12 км для України).</p>" +
          "<p>На часові ряди кліматичних даних часто впливають деякі проблеми з контролем якості (пропущені значення, відхилення, помилки тощо) та артефакти (різкі зсуви або поступові тренди). Останні, які зазвичай називають кліматологічною неоднорідністю або станційним сигналом, можуть виникати внаслідок різних факторів, не пов'язаних зі зміною клімату. Природа їх появи є штучною і полягає у зміні методів вимірювання, зміні спостерігача або обладнання; зміні природних умов навколо станцій або при переміщенні станцій. Такі небажані ефекти були максимально усунуті для отримання згаданих сіткових часових рядів для України.</p>" +
          "<p>Алгоритм картографування (або інтерполяції) був складним і використовував програмне забезпечення <a href='https://scholar.google.com/scholar_lookup?title=Manual%20of%20Interpolation%20Software%20MISHv1.03&publication_year=2014&author=T.%20Szentimrey&author=Z.%20Bihari'>MISH</a> (Meteorological Interpolation based on Surface Homogenized Data Basis). Алгоритм MISH - це метод оцінки метеорологічних даних у регіонах, де вимірювання можуть бути недоступними. У ньому використовуються ідеї з методів просторового моделювання, таких як кригінг (спосіб прогнозування значень на основі близьких вимірювань), однак він також включає цінну інформацію з довгострокових, ретельно перевірених метеорологічних даних. Для підвищення точності MISH враховує такі фактори, як висота над рівнем моря (рельєф), особливості рельєфу місцевості (місцева топографія), а також відстань від Чорного та Азовського морів. Ці фактори допомагають робити якісніші прогнози щодо погодних умов.</p>" +
          "<p class='small-container'>Climate & Water використовує <a href='https://uhmi.org.ua/data_repo/ClimUAd_Ukrainian_gridded_daily'>сіткові кліматичні часові ряди України</a> (сітка 0,10°x0,10° або близько 12x12 км) для розрахунку середньої температури повітря та опадів за історичний період (1991-2020). Ці дані можуть відображатися як кліматична норма, якщо обрати опцію «Спостереження» в головному меню (вкладка «Опади та температура»).</p>" +
          "</div>",

      historicalFutureWaterCycleTitle: "<div class='headline4 small-container'>4. Історичний та майбутній водний цикл: Що таке гідрологічна модель?</div>",
      historicalFutureWaterCycleContent: "<div class='small-container'>" +
          "<p>Із появою мов програмування, у другій половині 20 ст., фізичні та емпіричні рівняння, що описують окремі процеси водного циклу (випаровування, поверхневий стік, інфільтрація. тощо), почали об’єднувати у комплексні алгоритми, що пов’язують вказані процеси в одне ціле - так і виникли так звані гідрологічні моделі, яких на сьогодні налічуються сотні по всьому світу.</p>" +
          "<p>Найбільш розповсюдженою моделлю стала модель <a href='https://swat.tamu.edu/'>Soil and Water Assessment Tool</a> (SWAT), розроблена на початку 1990-х років Джеффом Арнольдом у Міністерстві сільського господарства США (USDA) (<a href='https://elibrary.asabe.org/abstract.asp?aid=42256'>Arnold et al., 2012</a>). SWAT починався як невеликий проект, але швидко перетворився на глобальний ресурс. Його розвиток досі координується USDA, що забезпечує послідовність та інноваційність. Успіх моделі полягає в кількох ключових перевагах: вона ефективно збалансовує вхідні та вихідні дані, забезпечена чіткою документацією та включає корисні інструменти, що роблять її зручною для користувача.</p>" +
          "<p>Однак, найвагомішою перевагою є абсолютна відкритість та доступність коду, з яким кожен може ознайомитися або завантажити та модифікувати. Ця відкритість приваблює світову спільноту науковців, які працюють разом над вдосконаленням моделі вже понад 30 років. Таке поєднання інституційної підтримки та вдосконалень, ініційованих спільнотою, зробило SWAT динамічним інструментом для гідрологічних досліджень та управління водними ресурсами.</p>" +
          "<span class='with-back'>" +
            "<p>" +
            "Climate & Water використовує агрогідрологічну (або агроекосистемну) модель SWAT для моделювання ключових компонентів водного циклу, включаючи витрати води, об’єм стоку, випаровування (наприклад, транспірацію), підземні води та вологість ґрунту. Для історичного періоду (1991-2024 рр.) моделювання ґрунтується на кліматичному реаналізі ERA5-Land. Для майбутніх періодів (2021-2040, 2041-2060, 2061-2080, 2081-2100), а також для історичного базового періоду (1991-2020) симуляції проводяться окремо для кожної кліматичної моделі." +
            "</p>" +
            "<p style='margin-top: 16px'>" +
            "Відносні зміни розраховуються шляхом порівняння історичних та майбутніх симуляцій однієї і тієї ж кліматичної моделі. Абсолютні значення отримуються за допомогою історичних симуляцій на базі ERA5-Land, скоригованих із застосуванням відносних змін." +
            "</p>" +
          "</span>" +
          "<p>Для моделі SWAT по Україні ми почали збирати дані у 2018 році. Поряд із загальнодоступними глобальними наборами даних, такими як ґрунтовий покрив, рельєф та кліматичний реаналіз, ми розробили комплексні ґрунтові та сільськогосподарські бази даних, що охоплюють Україну та верхів'я її транскордонних річкових басейнів. База даних ґрунтів включає 325 ґрунтових профілів та 1 497 генетичних горизонтів, кожен з яких характеризується такими параметрами, як гранулометричний склад ґрунту, коефіцієнт фільтрації та іншими, як показано на рис. 2. Сільськогосподарська база даних містить щорічні дані про сівозміни для основних культур з 1980 року на рівні областей, включаючи інформацію про внесення добрив.</p>" +
          "</div>" +
          "<p>" +
          "<img src='/image/infoPage/source-key.jpg' alt='source'>" +
          "<div><b>Рис. 2</b> Основні вхідні дані для моделі SWAT (Soil and Water Assessment Tool)</div>" +
          "</p>" +
          "<p>" +
          "<img src='/image/infoPage/study-area.png' alt='source'>" +
          "<div><b>Рис. 3</b> Розробка моделі SWAT (Soil and Water Assessment Tool) для України</div>" +
          "</p>" +
          "<div class='small-container'>" +
          "<p>Модель була відкалібрована за даними спостережень витрат води з 52 пунктів моніторингу (рис. 3). Враховуючи значну роль процесів сніготанення у формуванні гідрологічного режиму річок України, висота снігового покриву також була відкалібрована за даними 61 пункту моніторингу в усіх фізико-географічних зонах. Основні результати дослідження кругообігу води доступні на веб-платформі <a href='https://landwater.uhmi.org.ua/'>Land & Water</a> і можуть бути завантажені у вільному доступі.</p>" +
          "<p>Наразі готується публікація з детальним описом даної роботи.</p>" +
          "</div>",

      sourcesOfInformationTitle: "<div class='headline4 small-container'>5. Використані джерела інформації</div>",
      sourcesOfInformationContent: "<p>" +
          "<ol class='small-container'>" +
          "<li>" +
          "Future Climate for Africa - Guide. 2017. <a href='http://www.futureclimateafrica.org/wp-content/uploads/2017/09/fcfa_gcm-guide-web.pdf'>http://www.futureclimateafrica.org/wp-content/uploads/2017/09/fcfa_gcm-guide-web.pdf</a>" +
          "</li>" +
          "<li>" +
          "Dupar, M., with McNamara, L. and Pacha, M. (2019). Communicating climate change: A practitioner’s guide. Cape Town: Climate and Development Knowledge Network." +
          "</li>" +
          "</ol>" +
          "</p>"
    },
    glosary: {
      mainTitle: "Глосарій",
      abstractNav: "Анотація",
      climateChangeNav: "Зміна клімату",
      climateModelNav: "Кліматична модель",
      climateNormNav: "Кліматична норма",
      climatePredictionNav: "Прогнозування клімату ",
      climateProjectionNav: "Кліматична проєкція",
      cmipNav: "Coupled Model Intercomparison Project (CMIP)",
      climateReanalysisNav: "Кліматичний реаналіз",
      emissionsNav: "Викиди",
      emissionsScenarioNav: "Сценарій викидів",
      ensembleNav: "Ансамбль",
      euroCordexNav: "EURO-CORDEX",
      globalWarmingNav: "Глобальне потепління",
      greenhouseGasNav: "Парниковий газ (ПГ) та парниковий ефект",
      hydrologicalModelNav: "Міжурядова група експертів зі зміни клімату (МГЕЗК)",
      hydroModelNav: "Гідрологічна модель",
      sourceNav: "Використані джерела інформації",

      abstractTitle: "",
      abstractContent: "<div class='small-container'>Кілька вичерпних глосаріїв доступні на спеціалізованих ресурсах, таких як Міжурядова група експертів зі зміни клімату (Глосарій МГЕЗК) або Європейська агенція довкілля (Глосарій ЄАД). Нижче ми наводимо глосарій, спеціально адаптований для веб-сервісу Climate & Water.</div>",

      climateChangeTitle: "<div class='headline4'>Зміна клімату</div>",
      climateChangeContent: "<div class='small-container'>" +
          "<p><b>Зміни в кліматичній системі Землі та їхній вплив на довкілля і суспільство.</b></p>" +
          "<p>Організація Об'єднаних Націй визначає зміну клімату як довгострокові зміни температури та погодних умов. Людська діяльність, така як спалювання викопного палива та вирубка лісів, спричинила утворення парникових газів, які підвищують середню глобальну температуру.</p>" +
          "<p>Наслідки зміни клімату величезні, включаючи все частіші та інтенсивніші екстремальні погодні явища, підвищення рівня моря, порушення режиму опадів і танення льоду в полярних і гірських регіонах.</p>" +
          "</div>",

      climateModelTitle: "<div class='headline4'>Кліматична модель</div>",
      climateModelContent: "<div class='small-container'>" +
          "<p>Чисельне представлення кліматичної системи, яке включає фізичні, хімічні та біологічні властивості її складових, а також їхню взаємодію та процеси зворотного зв'язку. Кліматичні моделі різняться за складністю, починаючи від спрощених моделей, що фокусуються на конкретних компонентах, і закінчуючи комплексними системами, які імітують всю кліматичну систему.</p>" +
          "<p>До них відносяться моделі загальної циркуляції атмосфери й океанів (МЗЦАО), які пропонують детальні симуляції взаємодій в атмосфері, океанах і льодових системах. Сучасні кліматичні моделі все частіше інтегрують хімію та біологію для підвищення реалістичності.</p>" +
          "<p>Вони використовуються як дослідницькі інструменти для вивчення та моделювання кліматичних процесів, так і для практичного застосування, наприклад, для щомісячних, сезонних та міжрічних кліматичних прогнозів.</p>" +
          "</div>",

      climateNormTitle: "<div class='headline4'>Кліматична норма</div>",
      climateNormContent: "<div class='small-container'>" +
          "<p>За даними Всесвітньої метеорологічної організації (ВМО), кліматична стандартна норма наразі відноситься до останнього 30-річного періоду, який закінчується в рік, що дорівнює нулю (1981-2010, 1991-2020 і т.д.), а не до 30-річних періодів, що не перекриваються (1931-1960, 1961-1990 і т.д.).</p>" +
          "<p>Однак період з 1961 по 1990 рік був збережений як стандартний базовий період для довгострокових оцінок зміни клімату.</p>" +
          "<p>Climate & Water використовує 1991-2020 роки як базовий період, виходячи з наявності даних.</p>" +
          "</div>",

      climatePredictionTitle: "<div class='headline4'>Прогнозування клімату</div>",
      climatePredictionContent: "<div class='small-container'>" +
          "<p>Прогнозування клімату або кліматичний прогноз - це результат спроби створити найбільш ймовірний опис або оцінку фактичного розвитку клімату в майбутньому (наприклад, у сезонному, міжрічному або довгостроковому часовому масштабі).</p>" +
          "</div>",

      climateProjectionTitle: "<div class='headline4'>Кліматична проєкція</div>",
      climateProjectionContent: "<div class='small-container'>" +
            "<p>Оцінка того, як клімат може змінитися в майбутньому, на основі сценаріїв викидів парникових газів, аерозолів або інших факторів. Ці проєкції використовують симуляції кліматичних моделей і різняться від прогнозів тим, що вони залежать від припущень щодо майбутньої діяльності людини, наприклад, економічного та технологічного розвитку, які можуть значно відрізнятися.</p>" +
            "</div>",

      cmipTitle: "<div class='headline4'>Coupled Model Intercomparison Project (CMIP)</div>",
      cmipContent: "<div class='small-container'>" +
            "<p>Глобальна колаборація між групами дослідників клімату з метою поглиблення розуміння кліматичних систем шляхом порівняння результатів, отриманих за допомогою різних кліматичних моделей. CMIP стандартизує експерименти та надає проєкції майбутнього клімату за різними сценаріями, підтримуючи основні кліматичні оцінки, такі як оцінки Міжурядова група експертів зі зміни клімату (IPCC).</p>" +
            "<p>CMIP5 (запущений у 2010 році) представив нові сценарії, відомі як репрезентативні траєкторії концентрації (RCP), що дають уявлення про реакцію клімату на різні траєкторії концентрацій парникових газів. Він покращив представлення фізичних процесів у моделях і запропонував широкі дані для вивчення мінливості клімату та довгострокових проєкцій.</p>" +
            "<p>CMIP6 (розпочатий у 2016 році) ґрунтується на CMIP5 шляхом включення нових сценаріїв, які називаються спільними соціально-економічними траєкторіями (SSPs), що поєднують соціально-економічні фактори з траєкторіями викидів. CMIP6 має моделі з вищою роздільною здатністю, вдосконалені симуляції фізичних, хімічних і біологічних процесів, а також ширший набір експериментів, що стосуються ключових кліматичних питань, таких як роль аерозолів, змін у землекористуванні та механізмів зворотного зв'язку.</p>" +
            "</div>",

      climateReanalysisTitle: "<div class='headline4'>Кліматичний реаналіз</div>",
      climateReanalysisContent: "<div class='small-container'>" +
          "<p>Реаналіз проводиться шляхом поєднання результатів чисельного прогнозування погоди зі спостереженнями за Земною кулею (зокрема, із супутників, метеостанцій та інших інструментів у атмосфері, океані та на суші) за допомогою комплексного математичного підходу, відомого як «асиміляція даних». Результатом є глобально повний і узгоджений у часі набір даних, який охоплює понад 80 років, що дозволяє нам оцінювати довгострокові зміни клімату Землі.</p>" +
          "</div>",

      emissionsTitle: "<div class='headline4'>Викиди</div>",
      emissionsContent: "<div class='small-container'>" +
            "<p>Викиди - це гази та інші речовини, які потрапляють в атмосферу в результаті людської діяльності, такої як промисловість, енергетика та транспорт. Викиди парникових газів, таких як метан і вуглекислий газ, починаючи з 1800-х років, призвели до глобального потепління.</p>" +
            "</div>",

      emissionsScenarioTitle: "<div class='headline4'>Сценарій викидів</div>",
      emissionsScenarioContent: "<div class='small-container'>" +
            "<p>Можлива майбутня динаміка викидів парникових газів та аерозолів, заснована на послідовних припущеннях про такі фактори, як зростання населення, економічний розвиток та технологічні зміни. Кліматичні моделі запускаються за різними сценаріями майбутніх викидів парникових газів - від найкращого сценарію (у разі проведення масштабних заходів зі скорочення обсягів викидів) до найгіршого сценарію (у випадку продовження зростання викидів без жодних заходів для їх скорочення). Вони генерують низку можливих кліматичних сценаріїв («проєкцій»).</p>" +
            "</div>",

      ensembleTitle: "<div class='headline4'>Ансамбль</div>",
      ensembleContent: "<div class='small-container'>" +
            "<p>Група паралельних симуляцій моделей, що використовуються для кліматичних проєкцій. Різниця в результатах між елементами ансамблю дає змогу оцінити діапазон можливих результатів.</p>" +
            "</div>",

      euroCordexTitle: "<div class='headline4'>EURO-CORDEX</div>",
      euroCordexContent: "<div class='small-container'>" +
            "<p>Європейське відділення міжнародної ініціативи CORDEX, яка є програмою, що фінансується Всесвітньою програмою дослідження клімату (WRCP) з метою організації міжнародної координованої структури для створення поліпшених регіональних проєкцій зміни клімату для всіх регіонів суходолу. Результати CORDEX слугуватимуть внеском у дослідження впливу зміни клімату та адаптації до нього в рамках П'ятого оціночного звіту (AR5) Міжурядової групи експертів з питань зміни клімату (МГЕЗК) та після неї.</p>" +
            "</div>",

      globalWarmingTitle: "<div class='headline4'>Глобальне потепління</div>",
      globalWarmingContent: "<div class='small-container'>" +
            "<p>Багато хто вживає терміни «зміна клімату» і «глобальне потепління» як взаємозамінні. У той час як глобальне потепління стосується підвищення температури поверхні Землі, «зміна клімату» охоплює також інші зміни в погодних умовах, такі як вітер і кількість опадів. Діяльність людини, яка підвищила рівень парникових газів в атмосфері, є основною причиною глобального потепління.</p>" +
            "<p>Деякі засоби масової інформації віддають перевагу терміну «глобальне нагрівання», а не «глобальне потепління», щоб краще відобразити гостроту кризи.</p>" +
            "</div>",

      greenhouseGasTitle: "<div class='headline4'>Парниковий газ (ПГ) та парниковий ефект</div>",
      greenhouseGasContent: "<div class='small-container'>" +
            "<p>Вуглекислий газ, метан, оксид азоту, фреони, гідрофреони та гідрохлорфреони називають парниковими газами, оскільки їхня наявність в атмосфері затримує сонячне тепло, нагріваючи повітря навколо земної поверхні подібно до скляних стін та даху теплиці. Це і є парниковий ефект.</p>" +
            "<p>Людська діяльність, наприклад, спалювання викопного палива, збільшує частку парникових газів в атмосфері. Це посилює парниковий ефект, затримує більше тепла і призводить до того, що Земля стає теплішою.</p>" +
            "</div>",

      hydrologicalModelTitle: "<div class='headline4'>Міжурядова група експертів зі зміни клімату (МГЕЗК)</div>",
      hydrologicalModelContent: "<div class='small-container'>" +
            "<p>Міжурядова група експертів зі зміни клімату (МГЕЗК) - це науковий орган, створений у 1988 році Всесвітньою метеорологічною організацією (ВМО) та Програмою ООН з навколишнього середовища. Її мета - інформувати уряди про останні досягнення науки про клімат і пояснювати, який вплив матиме зміна клімату на світ у найближчі десятиліття.</p>" +
            "<p>Наразі МГЕЗК налічує 195 країн-членів і об'єднує вчених з усього світу, які добровільно беруть участь у її діяльності. МГЕЗК не проводить оригінальних досліджень. Замість цього сотні вчених вивчають наявну наукову літературу і перетворюють її у всеосяжні Оціночні звіти. У цих звітах детально розглядаються причини зміни клімату, її наслідки на місцевості, а також те, як пом'якшення (обмеження зміни клімату) та адаптація до неї можуть допомогти захистити людей від найгірших наслідків.</p>" +
            "</div>",

      hydroModelTitle: "<div class='headline4'>Гідрологічна модель</div>",
      hydroModelContent: "<div class='small-container'>" +
            "<p>Модель, яка імітує рух, розподіл і якість води в межах гідрологічного циклу, використовуючи математичне представлення фізичних, хімічних і біологічних процесів. Ці моделі ґрунтуються на фундаментальних принципах, таких як збереження маси та енергії, і враховують такі процеси, як опади, випаровування, інфільтрація, поверхневий стік і підземний стік. Процес-орієнтовані гідрологічні моделі широко використовуються для розуміння водних ресурсів, прогнозування гідрологічних реакцій на зміни клімату або землекористування, а також для підтримки рішень щодо управління водними ресурсами.</p>" +
            "</div>",

      sourceTitle: "<div class='headline4'>Використані джерела інформації</div>",
      sourceContent: "<div class='small-container'>" +
            "<p>" +
            "<ol class='small-container'>" +
            "<li>" +
            "Future Climate for Africa - Guide. 2017. <a href='http://www.futureclimateafrica.org/wp-content/uploads/2017/09/fcfa_gcm-guide-web.pdf'>http://www.futureclimateafrica.org/wp-content/uploads/2017/09/fcfa_gcm-guide-web.pdf</a>" +
            "</li>" +
            "<li>" +
            "Dupar, M., with McNamara, L. and Pacha, M. (2019). Communicating climate change: A practitioner’s guide. Cape Town: Climate and Development Knowledge Network." +
            "</li>" +
            "</ol>" +
            "</p>" +
            "</div>",
    }
  }
}

const i18n = createI18n({
  locale: 'uk',
  fallbackLocale: 'en',
  messages
})

export default i18n
