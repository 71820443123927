import axiosInstance from '@/lib/axios'
import {useGlobalStore} from "@/stores/globalStore";

type SelectedTab =
    | 'discharge'
    | 'water_flow'
    | 'temperature'
    | 'precipitation'
    | 'evapotranspiration'
    | 'groundwater'
    | 'soil_water'

type CodeType =
  | '5.1.1'
  | '5.1.2'
  | '5.1.3'
  | '5.1.4'
  | 'М5.2'
  | 'М5.4'
  | 'М6.5'
  | 'А6.6'
  | '5.3.1'
  | '5.3.2'
  | '5.3.3'
  | 'М5.8_5.3.4'
  | 'М6.9'
  | 'М5.7'

type SourceType =
  | 'Mod6_MPI-ESM-LR_RCA4'
  | 'Mod5_HadGEM2-ES_RCA4'
  | 'Mod4_HadGEM-ES_RACMO22E'
  | 'Mod3_IPSL-CM5A-MR_RCA4'
  | 'Mod2_EC-EARTH_RACMO22E'
  | 'Mod1_EC-EARTH_HIRHAM5'
  | 'median'
  | 'simulated'

type SeasonType =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December'
  | 'spring'
  | 'summer'
  | 'autumn'
  | 'winter'
  | 'annual'

type YearRangeType = '1991-2020' | '2021-2040' | '2041-2060' | '2081-2100'

type ValueType = 'relative' | 'absolute'

type ScenarioType = 'rcp85' | 'rcp45' | 'norm'

export const getData = async (
  code: CodeType,
  source: SourceType,
  scenario: ScenarioType,
  season: SeasonType,
  year_range: YearRangeType,
  value_type: ValueType,
  selectedTab: SelectedTab
) => {
  if (
    season !== 'annual' &&
    season !== 'spring' &&
    season !== 'summer' &&
    season !== 'autumn' &&
    season !== 'winter'
  ) {
    season = season.charAt(0).toUpperCase() + season.slice(1)
  }

  const globalStore = useGlobalStore()

  let area = null
  if(selectedTab === 'water_flow' || selectedTab === 'discharge'){
    area = 'whole'
  } else {
    if(globalStore.selectedBasinCode === globalStore.selectedBasinCode && globalStore.territory === 'UkraineOnly'){
      area = 'within'
    }
    else {
      area = 'whole'
    }
  }

  return await axiosInstance.get('/climate_water/', {
    params: {
      code: code,
      source: source,
      scenario: scenario,
      season: season,
      year_range: year_range,
      value_type: value_type,
      // area: 'whole'
      area: area,
    }

    // Example request
    // https://test.api.uhmi.org.ua/climate_water/
    // code=5.1.1
    // source=Mod4_HadGEM-ES_RACMO22E
    // scenario=rcp45
    // year_range=2021-2040
    // season=August
    // value_type=relative
    // area=whole
  })
}

export const getDataAvg = async (
  code: CodeType,
  scenario: ScenarioType,
  season: SeasonType,
  year_range: YearRangeType,
  value_type: ValueType,
  selectedTab: string
) => {
  const models: SourceType[] = [
    'Mod1_EC-EARTH_HIRHAM5',
    'Mod2_EC-EARTH_RACMO22E',
    'Mod3_IPSL-CM5A-MR_RCA4',
    'Mod4_HadGEM-ES_RACMO22E',
    'Mod5_HadGEM2-ES_RCA4',
    'Mod6_MPI-ESM-LR_RCA4'
  ]

  const requestPromises = []

  requestPromises.push(getData(code, 'median', scenario, season, year_range, value_type, selectedTab))

  for (const model of models) {
    requestPromises.push(getData(code, model, scenario, season, year_range, value_type, selectedTab))
  }

  return (await Promise.all(requestPromises)).map((response) => response.data)
}

export const getNorm = async (code: CodeType, season: SeasonType, selectedTab: string) => {
  return await getData(code, 'simulated', 'norm', season, '1991-2020', 'absolute', selectedTab)
}

export const getRiverCycle = async (
  code: CodeType,
  scenario: ScenarioType,
  year_range: YearRangeType,
  value_type: ValueType,
  selectedTab: string
) => {
  const models: SourceType[] = [
    'Mod1_EC-EARTH_HIRHAM5',
    'Mod2_EC-EARTH_RACMO22E',
    'Mod3_IPSL-CM5A-MR_RCA4',
    'Mod4_HadGEM-ES_RACMO22E',
    'Mod5_HadGEM2-ES_RCA4',
    'Mod6_MPI-ESM-LR_RCA4'
  ]
  const seasons: SeasonType[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const result = []
  const median = []

  for (const season of seasons) {
    const medinaPromise = getData(code, 'median', scenario, season, year_range, value_type, selectedTab).then(
      (response) => response.data
    )
    const modelPromises = models.map((model) =>
      getData(code, model, scenario, season, year_range, value_type, selectedTab).then(
        (response) => response.data
      )
    )

    const [medina, ...modelData] = await Promise.all([medinaPromise, ...modelPromises])
    median.push(medina)

    let stats = {
      discharge: { min: medina.discharge, max: medina.discharge },
      evapotranspiration: { min: medina.evapotranspiration, max: medina.evapotranspiration },
      groundwater: { min: medina.groundwater, max: medina.groundwater },
      potential_evapotranspiration: {
        min: medina.potential_evapotranspiration,
        max: medina.potential_evapotranspiration
      },
      precipitation: { min: medina.precipitation, max: medina.precipitation },
      soil_water: { min: medina.soil_water, max: medina.soil_water },
      temperature: { min: medina.temperature, max: medina.temperature },
      water_flow_area: { min: medina.water_flow_area, max: medina.water_flow_area },
      water_flow_outlet: { min: medina.water_flow_outlet, max: medina.water_flow_outlet }
    }

    modelData.forEach((data) => {
      Object.keys(stats).forEach((key) => {
        stats[key].min = Math.min(stats[key].min, data[key])
        stats[key].max = Math.max(stats[key].max, data[key])
      })
    })

    result.push(stats)
  }

  return {
    median,
    result
  }
}

export const getNormRiverCycle = async (code: CodeType, selectedTab: string) => {
  const seasons: SeasonType[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const requests = []

  for (const season of seasons) {
    requests.push(getData(code, 'simulated', 'norm', season, '1991-2020', 'absolute', selectedTab))
  }

  return await Promise.all(requests)
}

// code
{
  // code: '5.1.1', // Суб-басейн Верхнього Дніпра
  // code: '5.1.2', // Суб-басейн Середнього Дніпра
  // code: '5.1.3', // Суб-басейн Нижнього Дніпра
  // code: '5.1.4', // Суб-басейн Прип'яті
  // code: '5.1.5', // Суб-басейн Десни
  // code: 'М5.2', // Річковий басейн Дністра
  // code: 'М5.4', // Басейн Південного Бугу
  // code: 'М6.5', // Басейн Дону
  // code: 'А6.6', // Басейн Вісли
  // code: '5.3.1', // Суб-басейн Тиси
  // code: '5.3.2', // Суббасейни Пруту
  // code: '5.3.3', // Суббасейни Сірету
  // code: 'М5.8_5.3.4', // Річковий басейн річок Причорномор'я та Нижнього Дунаю
  // code: 'М6.9', // Басейн річок Приазов’я
  // code: 'М5.7'  // Район басейну річок Криму
}

// scenario
{
  // scenario: 'rcp85'
  // scenario: 'rcp45'
  // scenario: 'norm'
}

// source
{
  // source: 'Mod6_MPI-ESM-LR_RCA4',
  // source: 'Mod5_HadGEM2-ES_RCA4',
  // source: 'Mod4_HadGEM-ES_RACMO22E',
  // source: 'Mod3_IPSL-CM5A-MR_RCA4',
  // source: 'Mod2_EC-EARTH_RACMO22E',
  // source: 'Mod1_EC-EARTH_HIRHAM5',
  // source: 'median',
  // source: 'simulated',
}

// season
{
  // season: "January",
  // season: "February",
  // season: "March",
  // season: "April",
  // season: "May",
  // season: "June",
  // season: "July",
  // season: "August",
  // season: "September",
  // season: "October",
  // season: "November",
  // season: "December",
  // season: "spring",
  // season: "summer",
  // season: "autumn",
  // season: "winter",
  // season: "annual",
}

// year_range
{
  // year_range: "1991-2020",
  // year_range: "2021-2040",
  // year_range: "2041-2060",
  // year_range: "2081-2100",
}
