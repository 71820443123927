<template>
  <div
    v-show="props.isOpen"
    @click="handleOutsideClick"
    :class="{
      'modal-overlay': props.isOpen
    }"
  >
      <div v-if="props.isOpen">
        <div class="modal-window" @click.stop>
          <button class="close-button" @click="closeModal">✖</button>
          <p class="headline2">Ви дійсно хочете скинути всі налаштування?</p>
          <p class="label1">Налаштування виводу даних будуть встановлені за замовчуванням</p>
          <div class="controls">
            <button @click="confirmReset">Так, скинути налаштування</button>
            <button @click="closeModal">Ні, не скидати</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script setup>
import { useGlobalStore } from '@/stores/globalStore'

const props = defineProps({
  isOpen: Boolean
})
const emit = defineEmits(['update:isOpen'])

const globalStore = useGlobalStore()

const closeModal = () => {
  emit('update:isOpen', false)
}

const confirmReset = () => {
  globalStore.resetMenuSetting()
  closeModal()
}

const handleOutsideClick = (event) => {
  if (event.target === event.currentTarget) {
    closeModal()
  }
}
</script>

<style scoped>
.modal-overlay {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-window {
  text-align: center;
  background-color: var(--color-light-gray);
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15);

  padding: 64px;
  border-radius: 5px;
  position: relative;
}

.headline2 {
  margin-bottom: 12px;
}

.label1 {
  margin-bottom: 40px;
}

.controls {
  display: flex;
  justify-content: space-between;

  button:first-child {
    margin-right: 24px;
    background-color: var(--color-primary-blue);
    color: var(--color-white);
  }

  button {
    cursor: pointer;
    font-size: 16px;
    width: 280px;
    line-height: 36px;
    font-weight: 500;
    border-radius: 8px;
    border: none;
    background-color: var(--color-white);
    color: var(--color-primary-blue);
  }
}

.close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  height: 48px;
  width: 48px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  color: var(--color-secondary-blue);
}
</style>
