<script setup lang="ts">
import { HeaderComponent } from '@/UI/Header'
import { FooterComponent } from '@/UI/Footer'
import InfoBlocks from '@/components/mainPage/components/InfoBlock'
import TableComponent from '@/components/mainPage/components/TableComponent'
import {useGlobalStore} from "@/stores/globalStore";
import {useI18n} from "vue-i18n";
import {computed} from "vue";

const globalStore = useGlobalStore();
const { locale } = useI18n()
const currentLocale = computed(() => locale.value)
</script>

<template>
  <header-component />
  <main>
    <div class="container">
      <div class="first-block">
        <div class="top-row">
          <div class="logo-block">
            <div class="content">
              <div class="logo-title">
                <span class="title">Climate</span>
                <span class="subtitle1" style="margin: 0 8px; vertical-align: super">&</span>
                <span class="title">Water</span>
              </div>
              <div class="subtitle">
                <div class="name headline3">
                  {{ $t('mainPage.logoBlock.describe') }}
                </div>
                <router-link to="/data/temperature"
                  ><button class="headline4 focus">
                    {{ $t('mainPage.logoBlock.goToData') }}
                  </button></router-link
                >
              </div>
            </div>
          </div>
          <table-component />
        </div>

        <div class="bottom-row">
          <InfoBlocks />
          <div class="describe body1">
            {{ $t('mainPage.descriptionStart') }}
            <template v-if="globalStore.selectedBigBasinCode !== globalStore.selectedBasinCode">{{ $t('mainPage.descriptionFor') }} {{currentLocale === 'uk' ? globalStore.selectedBasin.name_ua : globalStore.selectedBasin.name_en }}</template>
            {{ $t('mainPage.descriptionEnd') }}
          </div>
        </div>
      </div>
      <div class="second-block">
        <div class="text-block">
          <div class="headline3">
            <span v-html="$t('mainPage.aboutPlatform.title')"></span>
          </div>
          <div class="content">
            <div class="first-line body1">
              {{ $t('mainPage.aboutPlatform.text1') }}
            </div>

            <div class="body1">
              {{ $t('mainPage.aboutPlatform.text2') }}
            </div>

            <div class="body1 attention">
              {{ $t('mainPage.aboutPlatform.attention') }}
            </div>
          </div>
        </div>
        <div class="image-block"></div>
      </div>
    </div>
  </main>
  <footer-component />
</template>

<style lang="scss" scoped>
.first-block {
  margin-top: 32px;

  .top-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .logo-block {
      background-image: url('/image/mainPage/mainImage.png');
      background-size: cover;
      background-position: center top;
      border-radius: 30px;
      margin: -8px;

      width: 67.3%;
      height: 655px;

      display: flex;
      align-items: end;
      justify-content: start;

      color: white;
      font-size: 18px;

      .content {
        width: 100%;
        padding: 29px 24px;

        .subtitle {
          display: flex;
          justify-content: space-between;

          .name {
            width: 500px;
            margin-top: 16px;
          }
        }

        button {
          margin-right: 26px;
          margin-top: 21px;
          color: var(--color-primary-blue);
          height: 48px;
          width: 226px;
          background: var(--color-green-linear);
          border-radius: 12px;
          border: none;
          cursor: pointer;

          &:hover {
            background: var(--color-shadow16-black), var(--color-green-linear);
          }
        }
      }
    }
  }

  .bottom-row {
    margin-top: 12px;
    width: 66.2%;

    .describe {
      margin-top: 13px;
      width: 524px;
    }
  }
}

main {
  min-height: calc(100vh - 240px - 80px);
  margin-bottom: 61px;
}

.second-block {
  margin-top: 122px;
  display: flex;
  justify-content: space-between;

  .content {
    .first-line {
      margin-top: 33px;
    }

    .body1 {
      margin-bottom: 13px;
      &.attention {
        color: #910000;
        font-weight: bold;
      }
    }
  }

  .headline3 {
    color: var(--color-secondary-blue);
  }

  .text-block {
    width: calc(50% - 16px);
  }

  .image-block {
    width: calc(50% - 8px);
    background-size: cover;
    background: url('/image/mainPage/text-image.png') no-repeat center bottom;
    background-size: cover;
    border-radius: 28px;
    margin-left: 16px;
    height: 294px;
  }
}
</style>
