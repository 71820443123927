<script setup lang="ts">
import { Chart } from 'chart.js/auto'
import {
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch
} from 'vue'
import { getDataAvg, getNorm } from '@/components/dataPage/components/ChartComponent/api/api'
import { useGlobalStore } from '@/stores/globalStore'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const currentLocale = computed(() => locale.value)

interface Props {
  selectedDisplayType: 'average' | 'river_cycle' | 'map'
  tabSelected: string
  period: string
  periodYears: string
  compare: string | null
}

const chartRef = ref(null)

const props = defineProps<Props>()
const globalStore = useGlobalStore()

let dataForChart = ref([])

let chartSelector = null
let gradientMedian = null
let gradientMedianGreen = null
let gradient = null
let gradientGreen = null
let gradientUnhovered = null
let gradientUnhoveredGreen = null

const ukLabels = ['Медіана', 'Мод.1', 'Мод.2', 'Мод.3', 'Мод.4', 'Мод.5', 'Мод.6']
const enLabels = ['Median', 'Mod.1', 'Mod.2', 'Mod.3', 'Mod.4', 'Mod.5', 'Mod.6']

let chart = reactive<Chart<'line', any, unknown> | {}>({})

const tabs = {
  discharge: {
    en: 'Discharge',
    uk: 'Витрати води'
  },
  water_flow: {
    en: 'Water flow',
    uk: 'Об’єм стоку'
  },
  temperature: {
    en: 'Temperature',
    uk: 'Температура'
  },
  precipitation: {
    en: 'Precipitation',
    uk: 'Опади'
  },
  evapotranspiration: {
    en: 'Evapotranspiration',
    uk: 'Випаровування'
  },
  groundwater: {
    en: 'Groundwater',
    uk: 'Підземні води'
  },
  soil_water: {
    en: 'Soil water',
    uk: 'Вологість ґрунту'
  }
}

const models = {
  0: {
    en: 'Median',
    uk: 'Медіана'
  },
  1: {
    en: 'Mod.1 - EC-EARTH_HIRHAM5',
    uk: 'Мод.1 - EC-EARTH_HIRHAM5'
  },
  2: {
    en: 'Mod.2 - EC-EARTH_RACMO22E',
    uk: 'Мод.2 - EC-EARTH_RACMO22E'
  },
  3: {
    en: 'Mod.3 - IPSL-CM5A-MR_RCA4',
    uk: 'Мод.3 - IPSL-CM5A-MR_RCA4'
  },
  4: {
    en: 'Mod.4 - HadGEM-ES_RACMO22E',
    uk: 'Мод.4 - HadGEM-ES_RACMO22E'
  },
  5: {
    en: 'Mod.5 - HadGEM2-ES_RCA4',
    uk: 'Мод.5 - HadGEM2-ES_RCA4'
  },
  6: {
    en: 'Mod.6 - MPI-ESM-LR_RCA4',
    uk: 'Мод.6 - MPI-ESM-LR_RCA4'
  }
}

const period = {
  en: 'Period',
  uk: 'Період'
}

const refer = {
  en: 'Ref.',
  uk: 'Реф.'
}

const scenari = {
  en: 'Emissions scenario',
  uk: 'Сценарій викидів'
}

const getUnits = () => {
  let pidpis = null

  if (props.tabSelected === 'temperature') {
    pidpis = '°C'
  }

  if (currentLocale.value === 'uk') {
    if (props.tabSelected === 'discharge') {
      pidpis = 'м³/с'
    }

    if (props.tabSelected === 'water_flow') {
      pidpis = 'млн. м³'
    }

    if (
      props.tabSelected !== 'discharge' &&
      props.tabSelected !== 'water_flow' &&
      props.tabSelected !== 'temperature'
    ) {
      pidpis = 'мм'
    }
  }

  if (currentLocale.value === 'en') {
    if (props.tabSelected === 'discharge') {
      pidpis = 'm³/s'
    }

    if (props.tabSelected === 'water_flow') {
      pidpis = 'mln. m³'
    }

    if (
      props.tabSelected !== 'discharge' &&
      props.tabSelected !== 'water_flow' &&
      props.tabSelected !== 'temperature'
    ) {
      pidpis = 'mm'
    }
  }

  if (globalStore.evaporation === 'potential' && props.tabSelected === 'evapotranspiration') {
    pidpis = '%'
  }

  if (globalStore.units === 'relative' && props.tabSelected !== 'temperature') {
    pidpis = '%'
  }

  return pidpis
}

const loadChart = async () => {
  chartSelector = document.getElementById('chart')
  const ctx = chartSelector.getContext('2d')

  gradientMedian = ctx.createLinearGradient(0, 0, chartSelector.clientWidth, 0) // Gradient along the x-axis
  gradientMedian.addColorStop(0, '#082f57')
  gradientMedian.addColorStop(1, '#082F57')

  gradient = ctx.createLinearGradient(0, 0, chartSelector.clientWidth, 0) // Gradient along the x-axis
  gradient.addColorStop(0, '#3182BD')
  gradient.addColorStop(1, '#3182BD')

  gradientUnhovered = ctx.createLinearGradient(0, 0, chartSelector.clientWidth, 0) // Gradient along the x-axis
  gradientUnhovered.addColorStop(0, '#6FA6C7')
  gradientUnhovered.addColorStop(1, '#6FA6C7')

  gradientMedianGreen = ctx.createLinearGradient(0, 0, chartSelector.clientWidth, 0) // Gradient along the x-axis
  gradientMedianGreen.addColorStop(0, '#0A712D')
  gradientMedianGreen.addColorStop(1, '#0A712D')

  gradientGreen = ctx.createLinearGradient(0, 0, chartSelector.clientWidth, 0) // Gradient along the x-axis
  gradientGreen.addColorStop(0, '#74C376')
  gradientGreen.addColorStop(1, '#74C476')

  gradientUnhoveredGreen = ctx.createLinearGradient(0, 0, chartSelector.clientWidth, 0) // Gradient along the x-axis
  gradientUnhoveredGreen.addColorStop(0, '#B9E3B2')
  gradientUnhoveredGreen.addColorStop(1, '#B9E3B2')

  try {
    chart = new Chart(chartSelector as HTMLCanvasElement, {
      type: 'bar',
      data: {
        labels: currentLocale.value === 'en' ? enLabels : ukLabels,
        datasets: [
          {
            order: 5,
            backgroundColor: [
              '#082f57',
              '#3182BD',
              '#3182BD',
              '#3182BD',
              '#3182BD',
              '#3182BD',
              '#3182BD'
            ],
            data: [],
            borderWidth: 0,
            borderRadius: 3,
            barPercentage: 1,
            categoryPercentage: 0.5
          }
        ]
      },
      options: {
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              display: true,
              color: '#e1e1e1',
              lineWidth: 1,
              drawTicks: true,
              offset: false
            },
            ticks: {
              font: {
                size: 15,
                weight: 600
              },
              color: '#6C7681'
            }
          },
          y: {
            grace: '1%',
            ticks: {
              font: {
                size: 16,
                weight: 'bold'
              },
              color: '#6C7681',
              padding: 14,
              callback: function (value, index, values) {
                const pidpis = getUnits()

                if (index === values.length - 1) {
                  return `${value} ${pidpis}`
                }
                return value
              }
            },

            stacked: false,
            beginAtZero: true,
            border: {
              display: false
            },
            grid: {
              drawTicks: false,
              color: '#D3DCE4',
              lineWidth: 2
            }
          }
        },
        onHover: (event, chartElement) => {
          const chart = event.chart
          if (chartElement.length > 0) {
            const activeElement = chartElement[0]
            const activeDatasetIndex = activeElement.datasetIndex
            const activeIndex = activeElement.index

            chart.data.datasets.forEach((dataset, datasetIndex) => {
              dataset.backgroundColor = dataset.backgroundColor.map((color, index) => {
                if (datasetIndex === activeDatasetIndex && index === activeIndex) {
                  return datasetIndex === 0 ? '#3182BD' : '#74C376'
                } else {
                  if (index === 0) {
                    return datasetIndex === 0 ? '#082f57' : '#0A712D'
                  }
                  return datasetIndex === 0 ? gradientUnhovered : gradientUnhoveredGreen
                }
              })
            })

            chart.update()
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            bodyColor: '#000',
            borderColor: '#ccc',
            borderWidth: 1,
            external: function (context) {
              const tooltipModel = context.tooltip
              if (!tooltipModel.opacity) {
                return
              }

              let columnIndex =
                tooltipModel.dataPoints.length > 0 ? tooltipModel.dataPoints[0].dataIndex : null
              let datasetIndex =
                tooltipModel.dataPoints.length > 0 ? tooltipModel.dataPoints[0].datasetIndex : null
              let color =
                tooltipModel.dataPoints.length > 0
                  ? context.chart.data.datasets[datasetIndex].backgroundColor[columnIndex]
                  : '#FFFFFF'

              let tooltipEl = document.getElementById('chartjs-tooltip')
              if (!tooltipEl) {
                tooltipEl = document.createElement('div')
                tooltipEl.id = 'chartjs-tooltip'
                tooltipEl.innerHTML =
                  "<div style='padding: 5px; border-radius: 4px' class='tooltip-inner'></div>"
                document.body.appendChild(tooltipEl)
              }

              tooltipEl.style.boxShadow = '2px 2px 6px rgba(0,0,0,0.2)'

              const position = context.chart.canvas.getBoundingClientRect()
              tooltipEl.style.background = 'white'
              tooltipEl.style.opacity = 1
              tooltipEl.style.position = 'absolute'
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + 50 + 'px'
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY - 30 + 'px'
              tooltipEl.style.fontFamily = tooltipModel.options.bodyFont.family
              tooltipEl.style.fontSize = tooltipModel.options.bodyFont.size + 'px'
              tooltipEl.style.fontStyle = tooltipModel.options.bodyFont.style
              tooltipEl.style.padding =
                tooltipModel.options.padding + 'px ' + tooltipModel.options.padding + 'px'
              tooltipEl.style.pointerEvents = 'none'

              let innerHtml = ''
              if (props.compare === 'rcp45' || props.compare === 'rcp85') {
                innerHtml += `<div style="margin-bottom: 4px; font-size: 14px; color: #666;">
                                  <span style="height: 16px; width: 16px; border-radius: 16px; background-color: ${color}; display: inline-block; margin-right: 5px;"></span>
                                  <span style="font-family: Manrope;font-size: 16px;font-weight: 700;line-height: 24px;letter-spacing: 0.1px;color: rgba(8, 47, 87, 1);">${currentLocale.value === 'en' ? tabs[props.tabSelected].en : tabs[props.tabSelected].uk} ${tooltipModel.body[0].lines[0]} ${getUnits()}</span>
                              </div>`
              } else {
                innerHtml += `<div style="font-family: Manrope;font-size: 16px;font-weight: 700;line-height: 24px;letter-spacing: 0.1px;color: rgba(8, 47, 87, 1);">${currentLocale.value === 'en' ? tabs[props.tabSelected].en : tabs[props.tabSelected].uk} ${tooltipModel.body[0].lines[0]} ${getUnits()}</div>`
              }
              innerHtml += `
                              <div style="font-family: Manrope;font-size: 16px;font-weight: 700;line-height: 24px;letter-spacing: 0.1px;color: rgba(8, 47, 87, 1);">${currentLocale.value === 'en' ? models[columnIndex].en : models[columnIndex].uk}</div>
                              <div style="font-family: Manrope;
                                  font-size: 15px;
                                  font-weight: 400;
                                  line-height: 22.5px;
                                  letter-spacing: 0.1px;
                              ">${currentLocale.value === 'en' ? period.en : period.uk} ${props.periodYears}${currentLocale.value === 'en' ? '' : ' рр.'};</div>
                              <div style="font-family: Manrope;
                                  font-size: 15px;
                                  font-weight: 400;
                                  line-height: 22.5px;
                                  letter-spacing: 0.1px;
                              ">${currentLocale.value === 'en' ? refer.en : refer.uk} 1991-2020${currentLocale.value === 'en' ? '' : ' рр.'};</div>
                              `
              const rcp = globalStore.sceneriesAtmosphere === 'rcp45' ? 'RCP4.5' : 'RCP8.5'
              const rcp1 = globalStore.sceneriesAtmosphere === 'rcp45' ? 'RCP8.5' : 'RCP4.5'

              innerHtml += `<div style="font-family: Manrope;
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 22.5px;
                  letter-spacing: 0.1px;
                  ">${currentLocale.value === 'en' ? scenari.en : scenari.uk} - <b style="font-weight: bold">${datasetIndex === 1 ? rcp1 : rcp}</b>;</div>`

              const tableRoot = tooltipEl.querySelector('.tooltip-inner')
              tableRoot.innerHTML = innerHtml
            }
          }
        }
      }
    }) as any // Chart<'line', any, unknown> , but problem with TS
  } catch (e) {
    console.log(e)
  }
}

const getParameterToDisplay = computed(() => {
  if (props.tabSelected === 'evapotranspiration') {
    if (globalStore.evaporation === 'potential') return 'potential_evapotranspiration'
    if (globalStore.evaporation === 'fact') return 'evapotranspiration'
  }

  return props.tabSelected
})

const getDataFromResponses = (el) => {
  if (getParameterToDisplay.value === 'water_flow') {
    if(el['water_flow_outlet'] === null){
      return el['water_flow_area']
    } else {
      return el['water_flow_outlet']
    }
  }
  return el[getParameterToDisplay.value]
}

const updateChart = async () => {
  chart.data.datasets[0].data = dataForChart.value.map(getDataFromResponses)

  if (props.compare === 'norm' && chart.data.datasets.length === 1) {
    chart.data.datasets.push({
      type: 'line',
      borderColor: '#212121',
      order: 3,
      borderWidth: 3,
      borderDash: [5, 5],
      fill: false,
      data: new Array(chart.data.datasets[0].data.length).fill(
        getDataFromResponses((await getNorm(globalStore.selectedBasinCode, props.period, props.tabSelected)).data)
      ),
      pointRadius: 0
    })
  } else if (props.compare === 'norm' && chart.data.datasets.length === 2) {
    chart.data.datasets[1].data = new Array(chart.data.datasets[0].data.length).fill(
      getDataFromResponses((await getNorm(globalStore.selectedBasinCode, props.period, props.tabSelected)).data)
    )
  } else if (props.compare === 'norm' && chart.data.datasets.length > 2) {
    chart.data.datasets = [chart.data.datasets[0], chart.data.datasets[1]]
    chart.data.datasets[1].data = new Array(chart.data.datasets[0].data.length).fill(
      getDataFromResponses((await getNorm(globalStore.selectedBasinCode, props.period, props.tabSelected)).data)
    )
  }

  if (
    (props.compare === 'rcp45' || props.compare === 'rcp85') &&
    chart.data.datasets.length === 1
  ) {
    chart.data.datasets.push({
      type: 'bar',
      order: 6,
      fill: false,
      data: (
        await getDataAvg(
          globalStore.selectedBasinCode,
          globalStore.sceneriesAtmosphere === 'rcp45' ? 'rcp85' : 'rcp45',
          props.period,
          props.periodYears,
          globalStore.units,
            props.tabSelected
        )
      ).map(getDataFromResponses),
      borderWidth: 0,
      borderRadius: 3,
      backgroundColor: [
        '#0A712D',
        '#74C376',
        '#74C376',
        '#74C376',
        '#74C376',
        '#74C376',
        '#74C376'
      ],
      // barPercentage: 1,
      // categoryPercentage: 0.8
      barPercentage: 1,
      categoryPercentage: 0.5
    })
  } else if (
    (props.compare === 'rcp45' || props.compare === 'rcp85') &&
    chart.data.datasets.length > 1
  ) {
    chart.data.datasets[1].data = (
      await getDataAvg(
        globalStore.selectedBasinCode,
        globalStore.sceneriesAtmosphere === 'rcp45' ? 'rcp85' : 'rcp45',
        props.period,
        props.periodYears,
        globalStore.units,
          props.tabSelected
      )
    ).map(getDataFromResponses)
  }

  chart.data.labels = currentLocale.value === 'en' ? enLabels : ukLabels
  chart.update()
}

watch(
  [
    () => globalStore.evaporation,
    () => props.tabSelected,
    () => dataForChart.value,
    () => currentLocale.value
  ],
  () => {
    updateChart()
  }
)

watch(
  [
    () => globalStore.selectedBasinCode,
    () => globalStore.sceneriesAtmosphere,
    () => props.period,
    () => props.periodYears,
    () => globalStore.units,
    () => globalStore.territory
  ],
  async () => {
    dataForChart.value = await getDataAvg(
      globalStore.selectedBasinCode,
      globalStore.sceneriesAtmosphere,
      props.period,
      props.periodYears,
      globalStore.units,
        props.tabSelected
    )
  },
  {
    immediate: true
  }
)

watch(
  () => globalStore.selectedBasinCode,
  () => {
    if (
      globalStore.selectedBasinCode === null ||
      globalStore.selectedBasinCode === undefined ||
      globalStore.selectedBasinCode === ''
    ) {
      dataForChart.value = [false]
      let i = 0
      for (const dataset of chart.data.datasets) {
        chart.data.datasets[i].data = []
        i++
      }
      chart.update()
    }
  }
)

onMounted(() => {
  loadChart()
})

const closeChart = (event) => {
  if (!event.target.closest('#chart')) {
    if (!chart) return
    chart.data.datasets[0].backgroundColor = chart.data.datasets[0].backgroundColor.map(
      (el, index) => (index === 0 ? gradientMedian : gradient)
    )
    if (chart.data.datasets.length > 1) {
      chart.data.datasets[1].backgroundColor = chart.data.datasets[0].backgroundColor.map(
        (el, index) => (index === 0 ? gradientMedianGreen : gradientGreen)
      )
    }

    const element = document.querySelector('#chartjs-tooltip')
    if (element) {
      element.parentNode.removeChild(element)
    }

    chart.update()
  }
}

watch(
  () => props.compare,
  async (newVal, oldVal) => {
    if (oldVal === 'norm' || oldVal === 'rcp45' || oldVal === 'rcp85') {
      chart.data.datasets = [chart.data.datasets[0]]
      // chart.update()
    }

    if (newVal === 'norm') {
      dataForChart.value = await getDataAvg(
        globalStore.selectedBasinCode,
        globalStore.sceneriesAtmosphere,
        props.period,
        props.periodYears,
        globalStore.units,
          props.tabSelected
      )

      chart.data.datasets.push({
        type: 'line',
        order: 3,
        borderColor: '#212121',
        borderWidth: 3,
        borderDash: [5, 5],
        fill: false,
        data: new Array(chart.data.datasets[0].data.length).fill(
          getDataFromResponses((await getNorm(globalStore.selectedBasinCode, props.period, props.tabSelected)).data)
        ),
        pointRadius: 0
      })
    }

    if (newVal === 'rcp45' || newVal === 'rcp85') {
      chart.data.datasets.push({
        type: 'bar',
        order: 6,
        fill: false,
        data: (
          await getDataAvg(
            globalStore.selectedBasinCode,
            globalStore.sceneriesAtmosphere === 'rcp45' ? 'rcp85' : 'rcp45',
            props.period,
            props.periodYears,
            globalStore.units,
              props.tabSelected
          )
        ).map(getDataFromResponses),
        borderWidth: 0,
        borderRadius: 3,
        backgroundColor: [
          '#0A712D',
          '#74C376',
          '#74C376',
          '#74C376',
          '#74C376',
          '#74C376',
          '#74C376'
        ],
        // barPercentage: 1,
        // categoryPercentage: 0.8
        barPercentage: 1,
        categoryPercentage: 0.5
      })
    }

    chart.update()
  }
)

onMounted(() => {
  document.addEventListener('mouseover', closeChart)
})

onBeforeUnmount(() => {
  document.removeEventListener('mouseover', closeChart)
})
</script>

<template>
  <div class="chart-block">
    <div class="chart-inner-block">
      <canvas
        :class="{
          disabled:
            dataForChart.map(getDataFromResponses)[0] === null ||
            dataForChart.map(getDataFromResponses)[0] === undefined ||
            dataForChart.map(getDataFromResponses)[0] === '' ||
            globalStore.selectedBasinCode === null ||
            globalStore.selectedBasinCode === undefined ||
            globalStore.selectedBasinCode === ''
        }"
        id="chart"
        ref="chartRef"
        width="1180"
        height="447"
        @mousemove.stop
      ></canvas>
      <div
        class="no-data"
        :class="{
          active:
            dataForChart.map(getDataFromResponses)[0] === null ||
            dataForChart.map(getDataFromResponses)[0] === undefined ||
            dataForChart.map(getDataFromResponses)[0] === '' ||
            globalStore.selectedBasinCode === null ||
            globalStore.selectedBasinCode === undefined ||
            globalStore.selectedBasinCode === ''
        }"
      >
        {{ $t('dataPage.chart.nodata') }}
      </div>
    </div>

    <template v-if="props.selectedDisplayType === 'average'">
      <template v-if="globalStore.projectResearch === 'CORDEX-EUR-11'">
        <div class="flex-table">
          <div class="flex-column">
            <div class="flex-row">
              <div class="label body1-bold">{{ $t('dataPage.chart.mod') }}.1</div>
              <div class="value body1">— EC-EARTH_HIRHAM5</div>
            </div>
            <div class="flex-row">
              <div class="label body1-bold">{{ $t('dataPage.chart.mod') }}.5</div>
              <div class="value body1">— HadGEM2-ES_RCA4</div>
            </div>
          </div>
          <div class="flex-column">
            <div class="flex-row">
              <div class="label body1-bold">{{ $t('dataPage.chart.mod') }}.2</div>
              <div class="value body1">— EC-EARTH_RACMO22E</div>
            </div>
            <div class="flex-row">
              <div class="label body1-bold">{{ $t('dataPage.chart.mod') }}.6</div>
              <div class="value body1">— MPI-ESM-LR_RCA4</div>
            </div>
          </div>
          <div class="flex-column">
            <div class="flex-row">
              <div class="label body1-bold">{{ $t('dataPage.chart.mod') }}.3</div>
              <div class="value body1">— IPSL-CM5A-MR_RCA4</div>
            </div>
          </div>
          <div class="flex-column">
            <div class="flex-row">
              <div class="label body1-bold">{{ $t('dataPage.chart.mod') }}.4</div>
              <div class="value body1">— HadGEM-ES_RACMO22E</div>
            </div>
          </div>
        </div>
      </template>

      <div class="description">
        <div>{{ $t('dataPage.chart.description1') }}</div>
        <div>
          <template v-if="globalStore.units === 'relative'">{{ $t('dataPage.chart.description2') }}</template>
          <template v-if="props.tabSelected === 'discharge'">
            <template v-if="globalStore.selectedBasinCode !== globalStore.selectedBigBasinCode">
              {{ $t('dataPage.chart.description2household') }}
            </template>
            <template v-else>{{ $t('dataPage.chart.description2end') }}</template>
          </template
          >
        </div>
      </div>

      <div
        class="legend"
        :class="{
          compare: props.compare === 'rcp45' || props.compare === 'rcp85'
        }"
      >
        <div class="el median" v-if="props.compare === 'norm'">
          <div class="el-norm">
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <div class="el-title">{{ $t('dataPage.chart.norm') }}</div>
        </div>
        <div
          class="el median"
          v-if="props.compare === 'null' || props.compare === null || props.compare === 'norm'"
        >
          <div class="el-color"></div>
          <div class="el-title">{{ $t('dataPage.chart.median') }}</div>
        </div>

        <template v-if="props.compare === 'rcp45' || props.compare === 'rcp85'">
          <div class="el median">
            <div class="el-color" style="background: #3182bd"></div>
            <div class="el-title">
              {{ globalStore.sceneriesAtmosphere === 'rcp85' ? 'RCP8.5' : 'RCP4.5' }}
            </div>
          </div>
          <div class="el median">
            <div class="el-color" style="background: #74c476"></div>
            <div class="el-title">
              {{ globalStore.sceneriesAtmosphere === 'rcp85' ? 'RCP4.5' : 'RCP8.5' }}
            </div>
          </div>
          <div class="el median">
            <div class="el-color" style="background: #082f57"></div>
            <div class="el-title">
              {{ $t('dataPage.chart.median') }}
              {{ globalStore.sceneriesAtmosphere === 'rcp85' ? 'RCP8.5' : 'RCP4.5' }}
            </div>
          </div>
          <div class="el median">
            <div class="el-color" style="background: #0a712d"></div>
            <div class="el-title">
              {{ $t('dataPage.chart.median') }}
              {{ globalStore.sceneriesAtmosphere === 'rcp85' ? 'RCP4.5' : 'RCP8.5' }}
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
#chart {
  margin: 12px auto 0;
}

.description {
  margin-top: 43px;
  margin-left: 15px;
}

.chart-block {
  position: relative;
}

.chart-inner-block {
  position: relative;

  #chart.disabled {
    opacity: 0.7;
  }

  .no-data {
    display: none;
  }

  .no-data.active {
    position: absolute;
    font-weight: bold;
    color: var(--color-dark-gray);
    font-size: 34px;
    top: 45%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }
}

.legend {
  display: flex;
  position: absolute;
  right: 195px;
  bottom: 70px;

  &.compare {
    right: 120px;
  }

  .el {
    display: flex;
    margin: 0 10px;
  }

  .el-color {
    height: 24px;
    width: 24px;
    border-radius: 4px;
    margin-right: 10px;
    background-color: red;
  }

  .el-norm {
    margin-right: 10px;

    .dot {
      display: inline-block;
      height: 3px;
      width: 9px;
      border-radius: 3px;
      background-color: black;
      margin-right: 6px;
      margin-bottom: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .median {
    .el-color {
      background-color: var(--color-primary-blue);
    }
  }
}

.flex-table {
  display: flex;
  width: 78%;
  margin: auto;
  font-size: 15px;
  margin-top: 24px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  //padding: 10px;
}

.flex-row {
  display: flex;
  margin-bottom: 5px;

  .label.body1-bold {
    //width: 30px;
    display: block;
  }
}

.flex-cell,
.label,
.value {
  //flex: 1;
  padding: 5px;
}

.label {
  padding-right: 0;
  text-align: right;
}

.value {
  padding-left: 4px;
  text-align: left;
}
</style>
